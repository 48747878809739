import { graphql } from "gatsby"
import React from "react"

const TaxQuery = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>

export const query = graphql`
  {
    allWpProject(
      filter: {
        taxServices: {
          nodes: { elemMatch: { slug: { eq: "master-planning" } } }
        }
      }
    ) {
      nodes {
        title
      }
    }
  }
`

export default TaxQuery
